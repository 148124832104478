import {memo, useMemo} from 'react';
import {CardHeader, Col, Row} from 'reactstrap';

import {formatDate} from '../../utils';
import {CountyFormDto, LocalUnitFormDto} from '../../types';

type Props = {
  form: CountyFormDto | LocalUnitFormDto
}

const FormHeader = ({
                      form
                    }: Props) => {
  // Remove 'Ad Valorem' or 'Special Acts' from the form name used in the card header as it
  // is also in the form's description
  const scrubbedFormName = useMemo(() => {
    return form.name?.replace('Ad Valorem', '')?.replace('Special Acts', '');
  }, [
    form.name
  ]);

  return <CardHeader className="bg-secondary text-white">
    <Row className="d-flex justify-content-between">
      <Col sm="12" md="7">
        Form {scrubbedFormName} {form.description}
      </Col>
      <Col sm="12" md="5" className="d-flex justify-content-md-end">
        Due: {formatDate(form.dueOn)}
      </Col>
    </Row>
  </CardHeader>;
};

export default memo(FormHeader);