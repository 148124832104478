import {format, isValid, parseISO} from 'date-fns';

import {isNumber} from './math';

export const formatDate = (dateValue: Date | string | null, dateFormat = 'MM/dd/yyyy') => {
  if (dateValue instanceof Date && isValid(dateValue)) {
    return format(dateValue, dateFormat);
  } else {
    const parsedDate = parseISO((dateValue as string));
    return (dateValue != null && isValid(parsedDate)) ? format(parsedDate, dateFormat) : undefined;
  }
};

export const formatTimestamp = (dateValue: Date | string | null) => {
  return formatDate(dateValue, 'MM/dd/yyyy h:mm a');
};

export const formatBoolean = (bool?: boolean | string | null) => {
  if (bool === undefined ||
    bool === null ||
    !['true', 'false', true, false].includes(bool)) {
    return null;
  }
  return ['true', true].includes(bool) ? 'Yes' : 'No';
};

export const formatDecimal = (value: string | number | null, places: number, isPercent = false) => {
  if (isNumber(value)) {
    return isPercent ? ((value as number) * 100).toFixed(places).toString() + '%' : (value as number).toFixed(places);
  } else {
    return value;
  }
};

export const formatAdjustmentModifier = (value: number | null) => {
  const sanitizedValue = typeof value === 'number' && isFinite(value) ? value : 0;
  return sanitizedValue > 0 ? sanitizedValue.toFixed(4) : null;
};

export const formatInteger = (value: number | null | string) => {
  return Number.isInteger(value) ? (value as number).toLocaleString() : value;
};

export const format4022AdjustmentValueWithCommas = (value: number | null | string) => {
  if (value === null) {
    return null;
  } else if (typeof value === 'number') {
    return value > 0 ? '+' + formatInteger(value) : formatInteger(value);
  } else {
    return value.toString();
  }
};
