import {useCallback, useEffect, useMemo, useState} from 'react';
import {Badge, Card, CardHeader, Container} from 'reactstrap';
import {useParams} from 'react-router-dom';

import {
  BreadcrumbsNav,
  ButtonIcon,
  CustomTable,
  ProgressIndicator,
  useAlerts,
  useUserContext
} from '@reasoncorp/kyber-js';

import {FormPdf, LocalUnitFormDto} from '../types';
import {localUnitFormApi} from '../api';
import * as messages from '../messages';
import {formatTimestamp} from '../utils';
import {openFileInNewWindow} from '../api/apiUtils';
import {usePortal} from '../hooks';

type Props = {
  isLocalUnit4022?: boolean
}

const LocalUnitFormPdfList = ({
                                isLocalUnit4022 = false
                              }: Props) => {
  console.log(isLocalUnit4022);

  const {
    countyId,
    countyFormId,
    localUnitFormId,
    localUnitId,
    year
  } = useParams() as {
    countyId: string,
    localUnitId: string,
    year: string,
    countyFormId: string,
    localUnitFormId: string
  };
  const {showErrorAlert} = useAlerts();
  const {permissions} = useUserContext();
  const [localUnitForm, setLocalUnitForm] = useState<LocalUnitFormDto | undefined>(undefined);
  const [loadingState, setLoadingState] = useState({loading: true, loadError: false});
  const {isStatePortal, isLocalUnitPortal} = usePortal();

  const breadcrumbs = useMemo(() => {
    if (!localUnitForm) {
      return [];
    } else if (isStatePortal) {
      return [{
        text: 'State  Dashboard',
        icon: 'home' as const,
        route: `/state-portal/forms/${year}`
      }, {
        text: localUnitForm!.countyDisplayName!,
        route: isLocalUnit4022 ?
          `/state-portal/${countyId}/${year}/forms/${countyFormId}/local-units/forms-local-unit` :
          `/state-portal/${countyId}/${year}/forms/${countyFormId}/local-units/forms`
      }, {
        text: localUnitForm!.localUnitDisplayName!,
        route: isLocalUnit4022 ?
          `/state-portal/${countyId}/${year}/forms/${countyFormId}/local-units/${localUnitId}/forms-local-unit/${localUnitForm!.id}` :
          `/state-portal/${countyId}/${year}/forms/${countyFormId}/local-units/${localUnitId}/forms/${localUnitForm!.id}`
      }, {
        text: 'PDF History',
        active: true
      }];
    } else if (permissions.isLocalUnitUser && isLocalUnitPortal) {
      return [{
        text: 'Local Unit Dashboard',
        icon: 'home' as const,
        route: `/local-unit-portal/${localUnitId}/${year}`
      }, {
        text: localUnitForm!.localUnitDisplayName!,
        route: `/local-unit-portal/${localUnitId}/${year}/forms/${localUnitForm!.id}`
      }, {
        text: 'PDF History',
        active: true
      }];
    } else {
      return [{
        text: 'County Dashboard',
        icon: 'home' as const,
        route: `/county-portal/${countyId}/${year}`
      }, {
        text: localUnitForm!.countyDisplayName!,
        route: `/county-portal/${countyId}/${year}/forms/${countyFormId}/local-units`
      }, {
        text: localUnitForm!.localUnitDisplayName!,
        route: `/county-portal/${countyId}/${year}/forms/${countyFormId}/local-units/${localUnitId}/forms/${localUnitForm!.id!}`
      }, {
        text: 'PDF History',
        active: true
      }];
    }
  }, [
    countyFormId,
    countyId,
    isLocalUnit4022,
    isLocalUnitPortal,
    localUnitForm,
    localUnitId,
    permissions,
    isStatePortal,
    year
  ]);

  useEffect(() => {
    const initialLoad = async () => {
      try {
        const localUnitForm = await localUnitFormApi.find(localUnitId, localUnitFormId);
        setLocalUnitForm(localUnitForm);
        setLoadingState({loading: false, loadError: false});
      } catch (e) {
        setLoadingState({loading: false, loadError: true});
        showErrorAlert(messages.API_FAILURE, true);
      }
    };

    void initialLoad();
  }, [
    countyId,
    localUnitId,
    localUnitFormId,
    showErrorAlert
  ]);

  const getPdfBadgeText = useMemo(() => (submission: boolean,
                                         isLocalUnit: boolean,
                                         review: boolean) => {
    if (submission && isLocalUnit) {
      return 'Sent to County';
    } else if (submission && !isLocalUnit) {
      return 'Submitted to State';
    } else if (!submission && isLocalUnit) {
      return 'Local Unit Draft (saved version)';
    } else if (review) {
      return 'Sent to Local Unit';
    } else {
      return 'County Draft (saved version)';
    }
  }, []);

  const handleOpenPdf = useCallback((url: string) =>
      openFileInNewWindow(showErrorAlert, messages.VIEW_PDF_FAILURE)(url),
    [showErrorAlert]
  );

  const formPdfs = useMemo(() => {
    const pdfs = localUnitForm?.formPdfs ?? [];

    if (isStatePortal && isLocalUnit4022) {
      return pdfs.filter(({localUnit}) => localUnit);
    } else {
      const filteredPdfs: FormPdf[] = [];

      pdfs.forEach(pdf => {
        if (pdf.submission && filteredPdfs.filter(pdf => pdf.submission && !pdf.localUnit).length === 0) {
          filteredPdfs.push(pdf);
        } else if (pdf.submission && filteredPdfs.filter(pdf => pdf.submission && pdf.localUnit).length === 0) {
          filteredPdfs.push(pdf);
        } else if (!pdf.submission) {
          filteredPdfs.push(pdf);
        }
      });

      return filteredPdfs;
    }
  }, [
    isStatePortal,
    localUnitForm,
    isLocalUnit4022
  ]);

  const tableProps = useMemo(() => ({
    headers: [
      {title: 'Date', className: 'align-middle'},
      {title: 'Type', className: 'align-middle'},
      {title: 'User', className: 'align-middle'},
      {title: 'PDF', className: 'text-center align-middle'}
    ],
    items: formPdfs,
    initialSort: {sortKey: 'createdAt', direction: 'desc' as const},
    renderRow: ({
                  createdAt,
                  submission,
                  localUnit,
                  userFullName,
                  review,
                  url
                }: FormPdf) => {
      const color = (submission || review) ? 'success' : 'secondary';

      return <tr key={`${createdAt}-${submission}`}>
        <td className="w-20 align-middle">
          {formatTimestamp(createdAt)}
        </td>
        <td className="w-35 align-middle">
          <Badge color={color}>
            {getPdfBadgeText(submission, localUnit, review)}
          </Badge>
        </td>
        <td className="w-35 align-middle">
          {userFullName}
        </td>
        <td className="w-10 text-center align-middle">
          <ButtonIcon icon="file-pdf"
                      ariaLabel="Open PDF"
                      title="Open PDF"
                      className="text-primary"
                      onClick={() => handleOpenPdf(url)}/>
        </td>
      </tr>;
    }
  }), [
    getPdfBadgeText,
    handleOpenPdf,
    formPdfs
  ]);

  // Remove 'Ad Valorem' or 'Special Acts' from the form name used in the card header as it
  // is also in the form's description
  const scrubbedFormName = useMemo(() => {
    return localUnitForm?.name.replace('Ad Valorem', '')?.replace('Special Acts', '');
  }, [
    localUnitForm?.name
  ]);

  return <Container fluid>
    {loadingState.loading && !loadingState.loadError && <ProgressIndicator/>}
    {!loadingState.loadError && !loadingState.loading && localUnitForm && <>
      <BreadcrumbsNav breadcrumbs={breadcrumbs}/>
      <Card>
        <CardHeader>
          PDF History - Form {scrubbedFormName} {localUnitForm.description}
        </CardHeader>
        <CustomTable {...tableProps} />
      </Card>
    </>}
  </Container>;
};

export default LocalUnitFormPdfList;